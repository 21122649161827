// import React, {useEffect, useRef, useState} from "react";
// import {useParams} from "react-router-dom";
// import {Helmet} from "react-helmet";
// import QRCode from "react-qr-code";
// import image from "../logo192.png";
// const content = require("../siteName.json")

// function DepositPage() {
//   const {id} = useParams();
//   const qrCodeRef = useRef(null);
//   const [capturedImage, setCapturedImage] = useState(null);
//   const [qrCodeValue, setQrCodeValue] = useState(
//     "https://deposit.betfundr.com/depositPage/5555"
//   );

//   useEffect(() => {
//     // Capture the image
//     const captureImage = async () => {
//       try {
//         const qrCodeCanvas = qrCodeRef.current.querySelector("canvas");
//         const base64Image = qrCodeCanvas.toDataURL("image/jpeg");
//         setCapturedImage(base64Image);
//       } catch (error) {
//         console.error("Error capturing image:", error);
//       }
//     };

//     captureImage();
//   }, []);

//   return (
//     <div style={{display: "flex", height: "100vh", backgroundColor: "red"}}>
//       <Helmet>
//         <title>Deposit Page</title>
//               <meta property='og:title' content='Deposit Page' />
//               <meta property='description' content='Complete the deposit for moses' />
//               <meta property='og:description' content='Complete the deposit for moses' />
//         <meta
//           property='og:image'
//                   content={content.hostname + image}
//         />
//         <meta
//           property='og:url'
//                   content={content.hostname + window.location.pathname + window.location.search}
//         />
//         <meta name='twitter:card' content='summary_large_image' />
//         <meta name='twitter:image:alt' content='Qr Code' />
//       </Helmet>

//       {/* Render the QR code */}
//       <div ref={qrCodeRef}>
//         <QRCode value={qrCodeValue} size={150} />
//       </div>

//       <h1>Deposit Page</h1>
//     </div>
//   );
// }

// export default DepositPage;


import React, { useEffect, useState } from "react";

function DepositPage() {
  const [pageTitle, setPageTitle] = useState("Deposit Page");
  const [pageDescription, setPageDescription] = useState("Description for Deposit Page");
  const [pageImage, setPageImage] = useState("/src/logo512.png"); // Update with your image URL

  useEffect(() => {
    // Update meta tags here
    document.title = pageTitle;
    document.querySelector('meta[name="description"]').setAttribute("content", pageDescription);
    document.querySelector('meta[property="og:image"]').setAttribute("content", pageImage);
  }, [pageTitle, pageDescription, pageImage]);

  return (
    <div>
      <h1>Deposit Page</h1>
    </div>
  );
}

export default DepositPage;

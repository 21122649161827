import "./App.css";
import React from "react";
import {  Route, Routes } from "react-router-dom";

import DepositPage from "../src/Pages/DepositPage";

function App() {
  return (
    <div className="container">
      <Routes>
        <Route path="/DepositPage/:id" element={<DepositPage />} />
      </Routes>
    </div>
  );
}

export default App;
